import base from './base'
import instance from './http'

const common = {
  uploadImage (params) {
    return instance.post(`${base}/manage/v1/upload/uploadImage`, params)
  }

}
export default common

<template>
  <a-layout class="app-wrapper">
    <!--菜单-->
    <menu-index ref="secondChildCollapsed"></menu-index>
    <a-layout>
      <!--顶部-->
      <header-index @parentCollapsed="parentCollapsed"></header-index>

      <a-layout-content
        :style="{ margin: '20px 15px 0 15px', padding: '10px', background: '#fff', minHeight: '280px',height: '80vh',overflow: 'auto', }"
      >
        <router-view/>
      </a-layout-content>
      <footer-index></footer-index>
    </a-layout>
  </a-layout>
</template>
<script>
  import { defineComponent, ref } from 'vue';
  import { MenuIndex, HeaderIndex, FooterIndex } from './index'
  export default defineComponent({
    components: {
      'menu-index':MenuIndex,
      'header-index':HeaderIndex,
      'footer-index':FooterIndex,
    },
    created() {

    },
    methods:{
      parentCollapsed(val){
        this.$refs.secondChildCollapsed.secondChildCollapsed(val)
      }
    }

  });
</script>
<style lang="less">
  @import "./BasicLayout.less";

</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// eslint-disable-next-line no-unused-vars
import api from './request/api'
// import 'ant-design-vue/dist/antd.less' // antd css
import 'ant-design-vue/dist/antd.css'
import './global.less' // global style
// eslint-disable-next-line no-unused-vars
import Antd from 'ant-design-vue'
import FontIcon from "@/components/FontIcon/FontIcon";
import fun from './utils/function'
// eslint-disable-next-line no-unused-vars
const app = createApp(App)
app.config.globalProperties.$api = api
app.config.globalProperties.$fun = fun
app.config.productionTip = false
app.component('FontIcon', FontIcon)

app.use(Antd).use(store).use(router).mount('#app')

const ADMIN_USER_TOKEN = 'admin_user_token'
const USER_INFO = 'admin_user_info'
const MENU_LIST = 'menu_list'
const HTTP_URL_LIST = 'http_url_list'
const MENU_ID = 'menu_id'

// 登陆token
export function getToken () {
  return localStorage.getItem(ADMIN_USER_TOKEN)
}
export function setToken (token) {
  localStorage.setItem(ADMIN_USER_TOKEN, token)
}
export function removeToken () {
  localStorage.removeItem(ADMIN_USER_TOKEN)
}
// 设置用户
export function getUser () {
  return localStorage.getItem(USER_INFO)
}
export function setUser (user) {
  localStorage.setItem(USER_INFO, user)
}
export function removeUser () {
  localStorage.removeItem(USER_INFO)
}
// 设置菜单
export function getMenuList () {
  return localStorage.getItem(MENU_LIST)
}
export function setMenuList (menuList) {
  localStorage.setItem(MENU_LIST, menuList)
}
export function removeMenuList () {
  localStorage.removeItem(MENU_LIST)
}
// 设置路由
export function getHttpUrlList () {
  return localStorage.getItem(HTTP_URL_LIST)
}
export function setHttpUrlList (httpUrlList) {
  localStorage.setItem(HTTP_URL_LIST, httpUrlList)
}
export function removeHttpUrlList () {
  localStorage.removeItem(HTTP_URL_LIST)
}
// 设置点击的菜单ID
export function getMenuId () {
  return localStorage.getItem(MENU_ID)
}
export function setMenuId (menuId) {
  localStorage.setItem(MENU_ID, menuId)
}
export function removeMenuId () {
  localStorage.removeItem(MENU_ID)
}

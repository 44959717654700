import axios from 'axios'
import router from '@/router'
import notification from 'ant-design-vue/es/notification'
import { getToken, removeToken } from '@/utils/auth'

// 错误提示
const tip = msg => {
  notification.error({
    message: msg
  })
}

// 登录页面完成登录后返回当前页面
const toLogin = () => {
  removeToken()
  router.replace({
    path: '/login',
    query: {
      redirect: router.currentRoute.fullPath
    }
  })
}

// axios自定义请求头
// axios.defaults.headers.common["Device-Type"] = 'web';
// eslint-disable-next-line no-unused-vars
const API_PROXY_PREFIX = '/aps'
// eslint-disable-next-line no-unused-vars
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : API_PROXY_PREFIX
// 创建axios实例
const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 120
})

// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/form-data;charset=UTF-8'
instance.defaults.headers.post['Device-Type'] = 'web'

// 请求拦截
// 每次请求前，如果存在token则在请求头中携带token
instance.interceptors.request.use(
  config => {
    const token = getToken()
    if (token) {
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      config.headers['XX-Token'] = token
    }
    return config
  },
  error => Promise.error(error)
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  res => {
    // eslint-disable-next-line eqeqeq
    if (res.data.code == 1001) {
      tip('未登录，请您先登录后访问！')
      removeToken()
      toLogin()
      // eslint-disable-next-line eqeqeq
    } else if (res.data.code == 1002) {
      tip('登录过期，请您先登录后访问！')
      removeToken()
      toLogin()
      // eslint-disable-next-line eqeqeq
    } else if (res.data.code == 9999) {
      tip('没有权限！')
    } else {
      return res.status === 200 ? Promise.resolve(res) : Promise.reject(res)
    }
  },
  // 请求失败
  error => {
    tip(error.message)
    return Promise.reject(error)
  })

export default instance

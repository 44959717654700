<template>
  <a-layout-header class="header-index">
    <!--顶部 左侧-->
    <menu-unfold-outlined
      v-if="collapsed"
      class="trigger"
      @click="firstChildCollapsed()"
    />
    <menu-fold-outlined v-else class="trigger" @click="firstChildCollapsed()" />
    <!--右侧-->
    <a-tooltip title="刷新菜单">
      <font-icon type="icon-refresh" style="font-size: 20px;cursor: pointer;" @click="refreshMenu"/>
    </a-tooltip>

    <a-tooltip title="清除缓存">
      <font-icon type="icon-qinglihuancun" :size="20" style="cursor: pointer;margin-left: 20px" @click="clearCache"/>
    </a-tooltip>

    <a-dropdown placement="bottomRight" class="ant-header-index-right">
      <span class="ant-account-avatar">
        <a-avatar size="small" :src="user.avatar_url" class="antd-global-header-index-avatar" />
        <span>{{user.name}}</span>
      </span>
      <template v-slot:overlay>
        <a-menu class="ant-drop-down menu" :selected-keys="[]">
          <a-menu-item key="center" @click="handleToCenter">
            <font-icon type="icon-user1"/>
            个人信息
          </a-menu-item>
          <a-menu-item key="settings" @click="handleToPwd">
            <font-icon type="icon-setting"/>
            修改密码
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="logout" @click="handleLogout">
            <font-icon type="icon-sign-out"/>
            退出
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-layout-header>
</template>

<script>
  import { defineComponent, createVNode } from "vue";
  import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
  import { getToken, removeToken, getUser, removeMenuList, setMenuList, removeUser } from '@/utils/auth'
  export default defineComponent({
    components:{
      MenuUnfoldOutlined,
      MenuFoldOutlined,
      UserOutlined,
    },
    name: "HeaderIndex",
    data() {
      return {
        user:{},
        collapsed: false,
      }
    },
    created() {
      this.checkUser()
    },
    methods: {
      checkUser() {
        let user = getUser()
        let token = getToken()
        if(!user || !token){
          removeToken()
          this.$message.warning('登陆失效')
          this.$router.push({path:'/login'})
        }
        this.user = user ? JSON.parse(user) : {};
      },
      firstChildCollapsed() {
        this.$emit('parentCollapsed',this.collapsed = !this.collapsed);
      },
      handleToCenter(){
        this.$router.push({path: '/account/setting'})
      },
      handleToPwd(){
        this.$router.push({path: '/account/password'})
      },
      refreshMenu() {
        this.$api.auth.resetMenu().then(respond => {
          if(respond.data.code == 1){
            let menu_list = respond.data.data.menu_list
            setMenuList(JSON.stringify(menu_list))
            this.$message.success(respond.data.msg)
          }else{
            this.$message.error(respond.data.msg)
          }
        })
      },
      clearCache() {
        this.$api.auth.clearCache().then(respond => {
          if(respond.data.code == 1){
            this.$message.success(respond.data.msg)
          }else{
            this.$message.error(respond.data.msg)
          }
        })
      },
      handleLogout(){
        this.$confirm({
          title: '提示',
          icon: createVNode(ExclamationCircleOutlined),
          content: '您确定要退出吗？',
          cancelText: '取消',
          okText: '确定',
          onOk:() =>{
            return this.$api.auth.logout().then(respond => {
              if(respond.data.code == 1){
                this.$message.success('退出成功')
                removeToken()
                removeUser()
                removeMenuList()
                this.$router.push({ path: '/login' })
              }else{
                this.$message.error(respond.data.msg)
              }

            })
          },
          onCancel(){}
        })
      }
    }
  })
</script>

<style lang="less" scoped>
  .header-index{
    background: #fff; padding: 0
  }

  .ant-drop-down {
    :deep(.action) {
      margin-right: 2px;
    }
    :deep(.ant-dropdown-menu-item) {
      min-width: 70px;
    }
  }

  .trigger{
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .app-wrapper .trigger:hover {
    color: #1890ff;
  }


</style>

// 接口域名管理
let root
if (process.env.VUE_APP_MODE === 'development') {
  root = ''
} else if (process.env.VUE_APP_MODE === 'test') {
  // 测试环境下的执行操作
  root = ''
} else {
  // 生产环境下的执行操作
  root = ''
}

export default root

<template>
  <icon-font
    id="fonticon"
    :type="type"
    :style="{ fontSize: size + 'px' }"
  />
</template>

<script>
  import { createFromIconfontCN } from '@ant-design/icons-vue';
  const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_3344748_sf4f9h4qk1.js', // 在 iconfont.cn 上生成
  });
  export default {
    name: "FontIcon",
    data() {
      return {
      }
    },
    props: {
      type: { type: String, default: "icon-xiugai" },
      size: { type: Number, default: 22 },
    },
    created() {
    },
    components: {
      IconFont,
    },
    methods: {
    },
  }
</script>

<style lang="less" scoped>
  #fonticon {
    cursor: pointer;
  }
</style>

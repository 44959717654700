<template>
  <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible :style="{  height: '100vh', left: 0, top: 0, bottom: 0 }"><!--:style="{  height: '100vh', left: 0, top: 0, bottom: 0 }"-->
    <div class="header-logo" >
      <a href="/">
        <div class="title">银现金信贷</div>
      </a>
    </div>
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      @click="jumpRoute"
      @openChange="onOpenChange"
    >
      <template v-for="(route,k) in menu_list" :key="k">
        <a-sub-menu :key="route.id" v-if="route.children && route.children.length > 0">
          <template #title>
            <font-icon :type="route.icon"/>
            <span>{{route.menuName}}</span>
          </template>
          <a-menu-item v-for="(item,key) in route.children" :key="item.id" :url="item.path">{{item.menuName}}</a-menu-item>
        </a-sub-menu>

        <a-menu-item :key="route.id" v-else :url="route.path">
          <font-icon :type="route.icon"/>
          <span>{{route.menuName}}</span>
        </a-menu-item>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
  import { defineComponent, ref } from 'vue';
  import { getMenuList } from "@/utils/auth";
  export default defineComponent({
    data() {
      return {
        menu_list: {},
        openKeys: [1],
        selectedKeys: [1],
        flag: true
      }
    },
    props:{
      collapsed:{
        type:Boolean,
        default:false
      }
    },
    setup() {
      return {
        collapsed: ref(false),
      };
    },
    created() {
      this.getMenuList()
    },
    methods: {
      secondChildCollapsed(val) {
        this.collapsed = val
      },
      getMenuList() {
        let menu_list = getMenuList()
        menu_list = menu_list ? JSON.parse(menu_list) : {}
        this.menu_list = menu_list
        if(menu_list.length > 0){
          menu_list.forEach((item) => {
            if(item.children){
              item.children.forEach((obj) => {
                if (this.$route.path == "/" && this.flag) {
                  this.flag = false;
                  this.openKeys = [];
                  this.$router.push("/index");
                  document.title = `PAETAO-后台管理系统`;
                } else if (obj.path == this.$route.path) {
                  this.openKeys = [item.id];
                  this.selectedKeys = [obj.id];
                  document.title = `PAETAO-${obj.menuName}`;
                }
              })
            }else{
              if (this.$route.path == "/" && this.flag) {
                this.flag = false;
                this.openKeys = [];
                this.$router.push("/index");
                document.title = `PAETAO-后台管理系统`;
              }
            }
          })
        }

      },
      jumpRoute(e) {
        this.selectedKeys = [e.k]
        let url = e.item.url
        this.$router.push({path: url})
        this.menu_list.forEach((item) => {
          if(item.children){
            item.children.forEach((obj) => {
              if (obj.id == e.key) {
                document.title = `PAETAO-${obj.menuName}`;
              }
            })
          }else{
            if (item.id == e.key) {
              document.title = `PAETAO-${item.menuName}`;
            }
          }
        })
      },
      onOpenChange(openKeys) {
        if (openKeys.length == 0) {
          this.openKeys = [];
        } else {
          this.openKeys = [openKeys[openKeys.length - 1]];
        }
      },
    }

  });
</script>

<style lang="less" scoped>
.header-logo{
  height: 32px;
  margin: 16px;
}
.title{
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
  white-space: nowrap;
}
</style>

// 权限模块
import auth from './auth'
// 公共模块
import common from './common'
import list from './list'

// 导出接口
export default {
  auth,
  common,
  list,
}

<template>
  <a-layout-footer :style="{ textAlign: 'center' }">
    Copyright ©2022 Created by PEENTO
  </a-layout-footer>
</template>

<script>

</script>

<style scoped>

</style>

import base from './base'
import instance from './http'

const auth = {
  login (params) {
    return instance.post(`${base}/manage/v1/login/index`, params)
  },
  logout() {
    return instance.get(`${base}/manage/v1/login/logout`)
  },
  getIndex() {
    return instance.get(`${base}/manage/v1/index`);
  },
  // 修改个人信息
  infoSave (params) {
    return instance.post(`${base}/manage/v1/auth/infoSave`, params)
  },
  // 修改密码
  editPwd (params) {
    return instance.post(`${base}/manage/v1/auth/editPwd`, params)
  },
  // 清除缓存
  clearCache() {
    return instance.get(`${base}/manage/v1/auth/clearCache`);
  },
  // 刷新管理员菜单
  resetMenu() {
    return instance.get(`${base}/manage/v1/auth/resetMenu`);
  },
  // 菜单列表
  menuIndex() {
    return instance.get(`${base}/manage/v1/menu/index`);
  },
  // 添加、更新菜单
  menuSave (params) {
    return instance.post(`${base}/manage/v1/menu/dataSave`, params)
  },
  // 添加菜单时获取前两级列表
  menuAddList() {
    return instance.get(`${base}/manage/v1/menu/addMenu`);
  },
  // 删除菜单
  menuDel (params) {
    return instance.post(`${base}/manage/v1/menu/delete`, params)
  },
  // 获取启用的菜单列表-用于添加角色时使用
  menuShowList() {
    return instance.get(`${base}/manage/v1/menu/menuList`);
  },
  // 根据菜单ID及登陆的管理员获取可操作的按钮
  handleList (params) {
    return instance.post(`${base}/manage/v1/menu/handleList`, params)
  },
  // 角色列表
  roleIndex(params) {
    return instance.post(`${base}/manage/v1/role/index`, params);
  },
  // 添加、更新角色
  roleSave (params) {
    return instance.post(`${base}/manage/v1/role/dataSave`, params)
  },
  // 角色详情
  roleDetail (params) {
    return instance.post(`${base}/manage/v1/role/detail`, params)
  },
  // 删除菜单
  roleDel (params) {
    return instance.post(`${base}/manage/v1/role/delete`, params)
  },
  // 管理员列表
  managerIndex(params) {
    return instance.post(`${base}/manage/v1/manager/index`, params);
  },
  // 添加、更新管理员
  managerSave (params) {
    return instance.post(`${base}/manage/v1/manager/dataSave`, params)
  },
  // 管理员详情
  managerDetail (params) {
    return instance.post(`${base}/manage/v1/manager/detail`, params)
  },
  // 启用、禁用管理员
  managerSet (params) {
    return instance.post(`${base}/manage/v1/manager/setStatus`, params)
  },
  // 获取网站配置
  configDetail(){
    return instance.get(`${base}/manage/v1/config/index`);
  },
  // 存储网站配置
  configSave(params){
    return instance.post(`${base}/manage/v1/config/saveConfig`,params);
  },
  // 广告位列表
  slideIndex(params){
    return instance.post(`${base}/manage/v1/slide/index`,params);
  },
  // 广告位新增、修改
  slideSave(params){
    return instance.post(`${base}/manage/v1/slide/slideSave`,params);
  },
  // 广告位删除
  slideDel(params){
    return instance.post(`${base}/manage/v1/slide/slideDel`,params);
  },
  // 广告列表
  itemIndex(params){
    return instance.post(`${base}/manage/v1/slide/itemIndex`,params);
  },
  // 广告新增、修改
  itemSave(params){
    return instance.post(`${base}/manage/v1/slide/itemSave`,params);
  },
  // 删除广告
  itemDel(params){
    return instance.post(`${base}/manage/v1/slide/itemDel`,params);
  },


}
export default auth
